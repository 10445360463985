<template>
  <router-link v-if="person" :to="{ name: 'person', params: { id: person.id } }" class="person-component">
    <ImgComponent :img="person.img" width="400" height="400" class="person-component__image" />
    <div class="person-component__content">
      <span v-if="person.title" class="person-component__title">{{ person.title }}</span>
      <span v-if="person['post']" class="person-component__post">{{ person["post"].title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "PersonComponent",
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.person-component {
  display flex
  flex-direction column
  width 100%

  &__image {
    height 300px
    max-height 300px
    border-radius 10px 10px 0 0
    +below(1280px) {
      height 100%
      max-height 400px
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 15px
    padding 30px
    background-color var(--white)
    height 150px
  }

  &__title {
    font-weight 700
  }

  &__post {
    font-size .875rem
    line-height 22px
  }
}
</style>
